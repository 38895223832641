var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            { staticClass: "mx-auto", staticStyle: { width: "90%" } },
            [
              _c(
                "v-row",
                [
                  _c("v-card-title", { staticClass: "font-weight-bold pb-3" }, [
                    _vm._v("\n          " + _vm._s(_vm.title) + "\n        ")
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-container", { staticClass: "ma-0 pa-0" }, [
                    _c(
                      "table",
                      {
                        staticClass: "ma-0",
                        staticStyle: {
                          width: "100%",
                          "border-spacing": "5px",
                          "font-size": "14px",
                          "table-layout": "fixed"
                        }
                      },
                      [
                        _c(
                          "tbody",
                          _vm._l(_vm.baseRecords, function(baseRecord) {
                            return _c("tr", { key: baseRecord.key }, [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "text-left base_td_category_text px-2"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "header.baseInfoTable." +
                                            baseRecord.key
                                        )
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "pl-2 py-2",
                                  staticStyle: { border: "solid 1px #cccccc" }
                                },
                                _vm._l(
                                  _vm.splitMessageLine(baseRecord.value),
                                  function(message, index) {
                                    return _c(
                                      "p",
                                      { key: index, staticClass: "ma-0" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(message) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "font-weight-bold pt-2 pl-1 pa-0" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "label.finalReviewContract.selectedPlanContent"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _c(
                          "table",
                          {
                            staticClass: "ma-0",
                            staticStyle: {
                              width: "100%",
                              "border-spacing": "5px",
                              "font-size": "14px",
                              "table-layout": "fixed"
                            }
                          },
                          [
                            _c(
                              "tbody",
                              _vm._l(_vm.selectedPlanContents, function(
                                selectedPlanContent
                              ) {
                                return _c(
                                  "tr",
                                  { key: selectedPlanContent.key },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "text-left base_td_category_text px-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "label.finalReviewContract." +
                                                  selectedPlanContent.key
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "pl-2 py-2",
                                        staticStyle: {
                                          border: "solid 1px #cccccc"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(selectedPlanContent.value) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.hasFix
                      ? _c(
                          "div",
                          [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold pl-2 pt-2",
                                staticStyle: { "font-size": "18px" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "label.finalReviewContract.amendments"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._l(_vm.fixContents, function(fixContent) {
                              return _c(
                                "v-container",
                                {
                                  key: fixContent.index,
                                  staticClass: "px-0 py-0"
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold pl-2 pb-0 my-1"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(fixContent.description) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._l(fixContent.fix, function(fix, index) {
                                    return _c(
                                      "v-row",
                                      {
                                        key: "fix" + index,
                                        staticClass: "ml-4"
                                      },
                                      [
                                        fix.modificationItemName
                                          ? [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "5" }
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "my-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.checkFixIsPlan(
                                                            fix
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "7" }
                                                },
                                                _vm._l(
                                                  _vm.splitMessageLine(
                                                    fix.text
                                                  ),
                                                  function(message, index) {
                                                    return _c(
                                                      "p",
                                                      {
                                                        key: index,
                                                        staticClass: "my-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(message) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          : [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" }
                                                },
                                                _vm._l(
                                                  _vm.splitMessageLine(
                                                    fix.text
                                                  ),
                                                  function(message, index) {
                                                    return _c(
                                                      "p",
                                                      {
                                                        key: index,
                                                        staticClass: "my-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(message) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  })
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.docs.length > 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold pl-2 pt-2",
                                staticStyle: { "font-size": "18px" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "label.finalReviewContract.uploadedDocumentList"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._l(_vm.docs, function(doc) {
                              return _c("v-row", { key: doc.name }, [
                                _c("p", { staticClass: "pl-5 pr-2" }, [
                                  _vm._v("・" + _vm._s(doc.name))
                                ])
                              ])
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              !_vm.hasSeriousFix
                ? _c(
                    "div",
                    { staticClass: "pl-2 pr-2" },
                    [
                      !_vm.hasFix
                        ? _c("v-row", [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "description.finalReviewContract.explanation1"
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.hasFix
                        ? _c("v-row", [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "description.finalReviewContract.hasFix"
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.explanation2"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.explanation3-1"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.explanation3-2"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.explanation4"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.explanation5"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-row", [
                        _vm.isShowCheckBox
                          ? _c(
                              "div",
                              { staticClass: "text-center float-left mb-8" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isChecked,
                                      expression: "isChecked"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "checkbox",
                                    disabled: _vm.isCheckBoxDisabled
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.isChecked)
                                      ? _vm._i(_vm.isChecked, null) > -1
                                      : _vm.isChecked
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.isChecked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.isChecked = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.isChecked = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.isChecked = $$c
                                        }
                                      },
                                      _vm.onClickCheckBox
                                    ]
                                  }
                                }),
                                _c("label", { attrs: { for: "checkbox" } }, [
                                  _vm._v(_vm._s(_vm.$t("checkbox.notAttached")))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      !_vm.hasFix
                        ? _c("v-row", [
                            _c("strong", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "description.finalReviewContract.explanation6"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.hasFix
                        ? _c("v-row", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "description.finalReviewContract.hasFixConfirmation"
                                  )
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasSeriousFix
                ? _c(
                    "div",
                    { staticClass: "pl-2 pr-2" },
                    [
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.unconcluded1"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "description.finalReviewContract.unconcluded2"
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isError
                ? _c("v-row", { staticClass: "pl-2 pr-2 pt-4" }, [
                    _c(
                      "p",
                      [
                        _c("font", { attrs: { color: "error" } }, [
                          _vm._v(_vm._s(_vm.errorText))
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-12" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-left": "0px",
                        "padding-right": "10px",
                        "text-align": "center"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "33vw",
                            height: "50px",
                            "max-width": "170px",
                            color: "back",
                            rounded: "",
                            dark: !_vm.isBackButtonDisable,
                            disabled: _vm.isBackButtonDisable
                          },
                          on: { click: _vm.onBackClicked }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.back")))]
                      )
                    ],
                    1
                  ),
                  !_vm.hasSeriousFix
                    ? _c(
                        "v-col",
                        {
                          staticStyle: {
                            "padding-left": "10px",
                            "padding-right": "0px",
                            "text-align": "center"
                          }
                        },
                        [
                          !_vm.hasFix
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black mx-auto",
                                  staticStyle: { "font-size": "14px" },
                                  attrs: {
                                    align: "center",
                                    width: "33vw",
                                    height: "50px",
                                    "max-width": "170px",
                                    color: "next",
                                    rounded: "",
                                    dark: _vm.isApplyButtonEnabled,
                                    disabled: !_vm.isApplyButtonEnabled
                                  },
                                  on: { click: _vm.openDialog }
                                },
                                [_vm._v(_vm._s(_vm.$t("button.apply")))]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black mx-auto",
                                  staticStyle: { "font-size": "10px" },
                                  attrs: {
                                    align: "center",
                                    width: "33vw",
                                    height: "50px",
                                    "max-width": "170px",
                                    color: "next",
                                    rounded: "",
                                    dark: _vm.isApplyButtonEnabled,
                                    disabled: !_vm.isApplyButtonEnabled
                                  },
                                  on: { click: _vm.openDialog }
                                },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "py-0" },
                                    [
                                      _c("v-row", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-center mx-auto"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "button.applyWithFixHead"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-auto text-center" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-center mx-auto"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "button.applyWithFixCenter"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-auto text-center" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-center mx-auto"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "button.applyWithFixBottom"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasSeriousFix
                    ? _c(
                        "v-col",
                        {
                          staticStyle: {
                            "padding-left": "10px",
                            "padding-right": "0px",
                            "text-align": "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black mx-auto",
                              staticStyle: { "font-size": "10px" },
                              attrs: {
                                align: "center",
                                width: "33vw",
                                height: "50px",
                                "max-width": "170px",
                                color: "next",
                                rounded: "",
                                dark: _vm.isApplyButtonEnabled,
                                disabled: !_vm.isApplyButtonEnabled
                              },
                              on: { click: _vm.openDialog }
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "py-0" },
                                [
                                  _c("v-row", [
                                    _c(
                                      "span",
                                      { staticClass: "text-center mx-auto" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("button.newProposal1")
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-auto text-center" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-center mx-auto" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("button.newProposal2")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-auto text-center" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-center mx-auto" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("button.newProposal3")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showDialog,
                  title: null,
                  text: _vm.dialogText,
                  subText: _vm.$t("description.finalReviewContract.confirm2"),
                  negativeButtonTitle: _vm.$t("button.back"),
                  positiveButtonTitle: _vm.$t("button.yes"),
                  onClickNegativeButton: _vm.closeDialog,
                  onClickPositiveButton: _vm.apply
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }