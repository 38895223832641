<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%;  max-height=90vh;" class="mx-auto">
        <v-row>
          <v-card-title class="font-weight-bold pb-3">
            {{ title }}
          </v-card-title>
        </v-row>
        <v-row>
          <v-container class="ma-0 pa-0">
            <table
              class="ma-0"
              style="width: 100%; border-spacing: 5px; font-size: 14px; table-layout: fixed;"
            >
              <tbody>
                <tr v-for="baseRecord in baseRecords" :key="baseRecord.key">
                  <!-- 基本情報 -->
                  <td class="text-left base_td_category_text px-2">
                    {{ $t(`header.baseInfoTable.${baseRecord.key}`) }}
                  </td>
                  <!-- 内容 -->
                  <td class="pl-2 py-2" style="border: solid 1px #cccccc;">
                    <p
                      class="ma-0"
                      v-for="(message, index) in splitMessageLine(
                        baseRecord.value
                      )"
                      :key="index"
                    >
                      {{ message }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <v-card-title class="font-weight-bold pt-2 pl-1 pa-0">
                {{ $t('label.finalReviewContract.selectedPlanContent') }}
              </v-card-title>
              <table
                class="ma-0"
                style="width: 100%; border-spacing: 5px; font-size: 14px; table-layout: fixed;"
              >
                <tbody>
                  <tr
                    v-for="selectedPlanContent in selectedPlanContents"
                    :key="selectedPlanContent.key"
                  >
                    <!-- プラン情報 -->
                    <td class="text-left base_td_category_text px-2">
                      {{
                        $t(
                          `label.finalReviewContract.${selectedPlanContent.key}`
                        )
                      }}
                    </td>
                    <!-- 内容 -->
                    <td class="pl-2 py-2" style="border: solid 1px #cccccc;">
                      {{ selectedPlanContent.value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="hasFix">
              <p class="font-weight-bold pl-2 pt-2" style="font-size:18px">
                {{ $t('label.finalReviewContract.amendments') }}
              </p>
              <v-container
                v-for="fixContent in fixContents"
                :key="fixContent.index"
                class="px-0 py-0"
              >
                <p class="font-weight-bold pl-2 pb-0 my-1">
                  {{ fixContent.description }}
                </p>
                <v-row
                  v-for="(fix, index) in fixContent.fix"
                  :key="'fix' + index"
                  class="ml-4"
                >
                  <template v-if="fix.modificationItemName">
                    <v-col class="py-0" cols="5">
                      <p class="my-1">{{ checkFixIsPlan(fix) }}</p>
                    </v-col>
                    <v-col class="py-0" cols="7">
                      <p
                        v-for="(message, index) in splitMessageLine(fix.text)"
                        :key="index"
                        class="my-1"
                      >
                        {{ message }}
                      </p>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col class="py-0" cols="12">
                      <p
                        v-for="(message, index) in splitMessageLine(fix.text)"
                        :key="index"
                        class="my-1"
                      >
                        {{ message }}
                      </p>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </div>
            <div v-if="docs.length > 0">
              <p class="font-weight-bold pl-2 pt-2" style="font-size:18px">
                {{ $t('label.finalReviewContract.uploadedDocumentList') }}
              </p>
              <v-row v-for="doc in docs" :key="doc.name">
                <p class="pl-5 pr-2">・{{ doc.name }}</p>
              </v-row>
            </div>
          </v-container>
        </v-row>
        <div v-if="!hasSeriousFix" class="pl-2 pr-2">
          <v-row v-if="!hasFix">
            <p>{{ $t('description.finalReviewContract.explanation1') }}</p>
          </v-row>
          <v-row v-if="hasFix">
            <p>{{ $t('description.finalReviewContract.hasFix') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation2') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation3-1') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation3-2') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation4') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation5') }}</p>
          </v-row>
          <v-row>
            <div v-if="isShowCheckBox" class="text-center float-left mb-8">
              <input
                type="checkbox"
                id="checkbox"
                v-model="isChecked"
                :disabled="isCheckBoxDisabled"
                @change="onClickCheckBox"
              />
              <label for="checkbox">{{ $t('checkbox.notAttached') }}</label>
            </div>
          </v-row>
          <v-row v-if="!hasFix">
            <strong>
              {{ $t('description.finalReviewContract.explanation6') }}
            </strong>
          </v-row>
          <v-row v-if="hasFix">
            <strong>{{ $t('description.finalReviewContract.hasFixConfirmation') }}</strong>
          </v-row>
        </div>
        <div v-if="hasSeriousFix" class="pl-2 pr-2">
          <v-row>
            <p>{{ $t('description.finalReviewContract.unconcluded1') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.unconcluded2') }}</p>
          </v-row>
        </div>
        <v-row v-if="isError" class="pl-2 pr-2 pt-4">
          <p>
            <font color="error">{{ errorText }}</font>
          </p>
        </v-row>
        <v-row class="my-12">
          <v-col
            style="padding-left:0px; padding-right:10px; text-align:center"
          >
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              @click="onBackClicked"
              width="33vw"
              height="50px"
              max-width="170px"
              color="back"
              style="font-size:20px"
              rounded
              :dark="!isBackButtonDisable"
              :disabled="isBackButtonDisable"
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col
            v-if="!hasSeriousFix"
            style="padding-left:10px; padding-right:0px; text-align:center"
          >
            <v-btn
              v-if="!hasFix"
              class="font-weight-black mx-auto"
              align="center"
              width="33vw"
              height="50px"
              max-width="170px"
              color="next"
              style="font-size:14px"
              rounded
              :dark="isApplyButtonEnabled"
              :disabled="!isApplyButtonEnabled"
              @click="openDialog"
              >{{ $t('button.apply') }}</v-btn
            >
            <v-btn
              v-else
              class="font-weight-black mx-auto"
              align="center"
              width="33vw"
              height="50px"
              max-width="170px"
              color="next"
              style="font-size:10px"
              rounded
              :dark="isApplyButtonEnabled"
              :disabled="!isApplyButtonEnabled"
              @click="openDialog"
            >
              <v-container class="py-0">
                <v-row>
                  <span class="text-center mx-auto">{{
                    $t('button.applyWithFixHead')
                  }}</span>
                </v-row>
                <v-row class="mx-auto text-center">
                  <span class="text-center mx-auto">{{
                    $t('button.applyWithFixCenter')
                  }}</span>
                </v-row>
                <v-row class="mx-auto text-center">
                  <span class="text-center mx-auto">{{
                    $t('button.applyWithFixBottom')
                  }}</span>
                </v-row>
              </v-container>
            </v-btn>
          </v-col>
          <v-col
            v-if="hasSeriousFix"
            style="padding-left:10px; padding-right:0px; text-align:center"
          >
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="33vw"
              height="50px"
              max-width="170px"
              color="next"
              style="font-size:10px"
              rounded
              :dark="isApplyButtonEnabled"
              :disabled="!isApplyButtonEnabled"
              @click="openDialog"
            >
              <v-container class="py-0">
                <v-row>
                  <span class="text-center mx-auto">
                    {{ $t('button.newProposal1') }}
                  </span>
                </v-row>
                <v-row class="mx-auto text-center">
                  <span class="text-center mx-auto">
                    {{ $t('button.newProposal2') }}
                  </span>
                </v-row>
                <v-row class="mx-auto text-center">
                  <span class="text-center mx-auto">
                    {{ $t('button.newProposal3') }}
                  </span>
                </v-row>
              </v-container>
            </v-btn>
          </v-col>
        </v-row>
        <Dialog
          :maxWidth="320"
          :showDialog="showDialog"
          :title="null"
          :text="dialogText"
          :subText="$t('description.finalReviewContract.confirm2')"
          :negativeButtonTitle="$t('button.back')"
          :positiveButtonTitle="$t('button.yes')"
          :onClickNegativeButton="closeDialog"
          :onClickPositiveButton="apply"
        />
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import Dialog from '@/components/organisms/contractor/Dialog.vue';
import { mapActions, mapGetters } from 'vuex';
import { getBaseInfo, submitContract } from '@/apis/contractor/contracts';
import {
  completedStepNumber,
  PaymentDisplay,
  EarthquakeInsurancePeriodTypeCodeDisplay,
  ProductName,
} from '@/lib/const';
import {
  convertDate,
  convertFullNameKanji,
  convertInsuranceObject,
  splitMessageLine,
  // convertProductName,
  convertPayment,
  convertFirstPayment,
  convertBaseInsurancePeriod,
  convertJapaneseYenToCommaSeparated,
} from '@/lib/commonUtil';

export default {
  components: { Dialog },
  props: {
    docs: Array,
    onBackClicked: Function,
  },
  data: () => ({
    baseRecords: [
      { key: 'fullNameKanji', value: '' },
      { key: 'fullNameKana', value: '' },
      { key: 'insuranceType', value: '' },
      { key: 'policyNumber', value: '' },
      { key: 'previousPolicyNumber', value: '' },
      { key: 'startDate', value: '' },
      { key: 'address', value: '' },
      { key: 'location', value: '' },
      { key: 'insuranceObject', value: '' },
      { key: 'firstPayment', value: '' },
    ],
    selectedPlanContents: [
      { key: 'planName', value: '' },
      { key: 'endDate', value: '' },
      { key: 'baseInsurancePeriod', value: '' },
      { key: 'earthquakeInsurancePeriod', value: '' },
      { key: 'payment', value: '' },
      { key: 'monthly', value: '' },
      { key: 'yearly', value: '' },
    ],
    hasFix: false,
    hasSeriousFix: false,
    showDialog: false,
    dialogText: '',
    fixContents: [],
    title: '',
    isApplyButtonEnabled: true,
    isBackButtonDisable: false,
    isShowCheckBox: false,
    isCheckBoxDisabled: false,
    isChecked: false,
    isError: false,
    errorText: null,
  }),
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async apply() {
      this.closeDialog();
      if (this.isShowCheckBox) {
        this.isCheckBoxDisabled = true;
      }
      this.isApplyButtonEnabled = false;
      this.isBackButtonDisable = true;
      const file = this.docs.map(v => {
        return v.fileData;
      });
      const response = await submitContract(
        this.selectedPlan().id ? this.selectedPlan().id : '',
        this.fix(),
        file
      );
      if (response instanceof Error) {
        this.isApplyButtonEnabled = true;
        this.isCheckBoxDisabled = false;
        this.isBackButtonDisable = false;
        this.isError = true;
        return;
      }
      this.setPendingStep(completedStepNumber);
      this.setIsProcedureSkipped(false);
      this.$router.push({
        path: '/procedure_complete',
      });
    },
    onClickCheckBox() {
      this.$set(this, 'isApplyButtonEnabled', this.isChecked);
    },

    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },

    // 修正項目プラン判定
    checkFixIsPlan(fix) {
      let result = fix.modificationItemName;
      if (fix.planId) {
        result = fix.planName + '：' + result;
      }
      return result;
    },

    // 地震保険付帯確認が必要であるかどうか
    needsConfirmEarthquakeInsuranceAttached(plan, isFire) {
      // 火災保険でない場合、不要とする
      if (!isFire) return false;

      // 地震保険期間区分コードに記載がない場合、必要とする
      if (plan.earthquakeInsurancePeriodTypeCode === null) return true;

      // 基本保険金額に記載があるかつ地震保険金額に記載がない場合、必要とする
      if (
        (!!plan.object1BaseInsuranceAmount &&
          plan.object1EarthquakeInsuranceAmount === null) ||
        (!!plan.object2BaseInsuranceAmount &&
          plan.object2EarthquakeInsuranceAmount === null)
      )
        return true;

      return false;
    },

    ...mapGetters('contract', ['fix', 'selectedPlan']),
    ...mapActions('contract', [
      'setFix',
      'setPendingStep',
      'setIsProcedureSkipped',
    ]),
  },
  async mounted() {
    this.fixContents = this.fix();
    // ページ順にソート
    this.fixContents.sort(function(a, b) {
      return a.index - b.index;
    });

    // 修正あり
    if (this.fixContents.length > 0) {
      // 重要事項に修正があるか
      const important = this.fixContents.find(k => k.important == 1);
      if (important) {
        this.hasSeriousFix = true;
      }
      this.hasFix = true;

      this.dialogText = this.$t(
        important
          ? 'description.finalReviewContract.request-re-proposal'
          : 'description.finalReviewContract.hasFixConfirm'
      );
    } else {
      // 修正なし
      this.dialogText = this.$t('description.finalReviewContract.confirm1');
    }

    let buttonName;

    if (this.hasSeriousFix) {
      buttonName = `${this.$t('button.newProposal1')}${this.$t(
        'button.newProposal2'
      )}${this.$t('button.newProposal3')}`;
    } else if (this.hasFix) {
      buttonName = `${this.$t('button.applyWithFixHead')}${this.$t(
        'button.applyWithFixCenter'
      )}${this.$t('button.applyWithFixBottom')}`;
    } else {
      buttonName = this.$t('button.apply');
    }

    this.errorText = `${this.$t(
      'error.failureToApplyHeader'
    )}「${buttonName}」ボタン${this.$t('error.failureToApplyFooter')}`;

    this.title = this.$t(
      this.hasSeriousFix
        ? 'title.contractor.reviewReceipt'
        : 'title.contractor.finalReviewContract'
    );

    const response = await getBaseInfo();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      // 初回保険料払込方法の加工
      const firstPayment = {
        accountTransferFlg: response.accountTransferFlg,
        creditCardFlg: response.creditCardFlg,
        paymentSlipFlg: response.paymentSlipFlg,
        cashPaymentFlg: response.cashPaymentFlg,
        debitCardFlg: response.debitCardFlg,
      };
      const tmpBaseRecord = [
        {
          key: 'fullNameKanji',
          value: convertFullNameKanji(
            response.fullNameKanji1,
            response.fullNameKanji2,
            response.fullNameKanji3
          ),
        },
        {
          key: 'fullNameKana',
          value: this.$t('label.honorificTitle', {
            name: `${response.fullNameKana1}${
              response.fullNameKana2.length > 0
                ? `\n${response.fullNameKana2}`
                : ''
            }${
              response.fullNameKana3.length > 0
                ? `\n${response.fullNameKana3}`
                : ''
            }`,
          }),
        },
        {
          key: 'insuranceType',
          value:
            response.productName == ProductName.Bl
              ? this.$t('label.productName.Bl')
              : this.$t('label.productName.Bk'),
          // value: convertProductName(
          //   response.productName,
          //   response.insuranceObject1,
          //   response.insuranceObject2
          // ),
        },
        { key: 'policyNumber', value: response.policyNumber },
        {
          key: 'startDate',
          value: convertDate(response.startDate),
        },
        {
          key: 'address',
          value: `${response.contractorAddress1}${response.contractorAddress2}${response.contractorAddress3}`,
        },
        {
          key: 'location',
          value: response.insuranceLocation1 || response.insuranceLocation2 || response.insuranceLocation3
            ? `${response.insuranceLocation1}${
                response.insuranceLocation2 ? response.insuranceLocation2 : ''
              }${
                response.insuranceLocation3 ? response.insuranceLocation3 : ''
              }`
            : this.$t('header.baseInfoTable.sameAsContractorAddress'),
        },
        {
          key: 'insuranceObject',
          value: convertInsuranceObject(
            response.insuranceObject1,
            response.insuranceObject2
          ),
        },
        {
          key: 'firstPayment',
          value: convertFirstPayment(firstPayment),
        },
      ];
      // 前契約証券番号がnullでない場合、証券番号の後ろに追加し画面上で表示する
      if (response.previousPolicyNumber) {
        const inserIndex = tmpBaseRecord.findIndex(
          ({ key }) => key === 'policyNumber'
        );
        tmpBaseRecord.splice(inserIndex + 1, 0, {
          key: 'previousPolicyNumber',
          value: response.previousPolicyNumber,
        });
      }
      this.$set(this, 'baseRecords', tmpBaseRecord);
    }

    if (this.selectedPlan()) {
      this.$set(this, 'selectedPlanContents', [
        { key: 'planName', value: this.selectedPlan().planName },
        {
          key: 'endDate',
          value: convertDate(this.selectedPlan().endDate),
        },
        {
          key: 'baseInsurancePeriod',
          value: convertBaseInsurancePeriod(
            this.selectedPlan().baseInsurancePeriodYear,
            this.selectedPlan().baseInsurancePeriodMonthDay,
            this.selectedPlan().baseInsurancePeriodMdfType
          ),
        },
        {
          key: 'earthquakeInsurancePeriod',
          value: this.selectedPlan().earthquakeInsurancePeriodTypeCode
            ? EarthquakeInsurancePeriodTypeCodeDisplay[
                this.selectedPlan().earthquakeInsurancePeriodTypeCode
              ]
            : 'ー',
        },
        {
          key: 'payment',
          value: convertPayment(
            response.productName,
            this.selectedPlan().payment,
            this.selectedPlan().baseInsurancePeriodYear,
            this.selectedPlan().baseInsurancePeriodMonthDay
          ),
        },
        // 保険料をカンマ区切りで表示
        {
          key: 'monthly',
          value: convertJapaneseYenToCommaSeparated(
            this.selectedPlan().monthly
          ),
        },
        {
          key: 'yearly',
          value: convertJapaneseYenToCommaSeparated(this.selectedPlan().yearly),
        },
      ]);
    }

    // 地震保険付帯確認チェックボックスの表示状態をセットする
    this.$set(
      this,
      'isShowCheckBox',
      this.needsConfirmEarthquakeInsuranceAttached(
        this.selectedPlan(),
        response.applicationForm.insuranceType.fireFlg
      )
    );

    // ボタンの活性化タイミングを判別
    if (this.hasSeriousFix) {
      this.$set(this, 'isApplyButtonEnabled', true);
    } else {
      this.$set(
        this,
        'isApplyButtonEnabled',
        this.isShowCheckBox ? false : true
      );
    }
  },
};
</script>
<style>
.v_base_table_with_border {
  border: solid 1px;
}
.base_td_category_text {
  width: 33%;
  background: #cccccc;
}
.v-application p {
  word-break: break-word;
}
.v-data-table {
  overflow: hidden;
}
</style>
